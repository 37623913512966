import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const CompanyContext = createContext();

export const useCompany = () => {
  return useContext(CompanyContext);
};

export const CompanyProvider = ({ children }) => {
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const siteName = window.location.hostname;
        const q = query(collection(db, 'companies'), where('siteName', '==', siteName));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const companyId = querySnapshot.docs[0].id;

          // Загружаем данные компании по companyId
          const companyDocRef = doc(db, 'companies', companyId);
          const companyDoc = await getDoc(companyDocRef);

          if (companyDoc.exists()) {
            setCompanyData({
              companyId: companyId,
              companyName: companyDoc.data().companyName,
              companyAdress: companyDoc.data().address,
              phone: companyDoc.data().phone,
            });
            console.log('Company Data:', companyDoc.data());
          } else {
            console.log('No company data found for ID:', companyId);
          }
        } else {
          console.log('No matching company found.');
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchCompanyData();
  }, []);

  return (
    <CompanyContext.Provider value={companyData}>
      {children}
    </CompanyContext.Provider>
  );
};
