import React, { useState, useEffect } from 'react';
import { List, Spin, message, Button, Alert } from 'antd';
import { ref, query, limitToLast, get } from 'firebase/database';
import { realtimeDb } from '../firebaseConfig';
import { getDoc, doc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { useCompany } from '../contexts/CompanyContext';
import { useNavigate } from 'react-router-dom';
import { db as firestoreDb } from '../firebaseConfig';

const InvoicesList = () => {
  const { currentUser } = useAuth();
  const companyData = useCompany();
  const companyId = companyData?.companyId;
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        if (!currentUser || !companyId) {
          message.error('Nie jesteś zalogowany lub brak danych firmy.');
          return;
        }

        // Получаем driverId из Firestore
        const userDocRef = doc(firestoreDb, `companies/${companyId}/users`, currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          message.error('Nie można znaleźć danych użytkownika.');
          return;
        }

        const driverId = userDoc.data().driverId;
        console.log('Driver ID:', driverId);

        // Запрос последних 5 записей из Realtime Database
        const invoicesRef = ref(realtimeDb, `/companies/${companyId}/drivers/${driverId}/invoices`);
        const invoicesQuery = query(invoicesRef, limitToLast(5));
        const snapshot = await get(invoicesQuery);

        if (snapshot.exists()) {
          const invoicesData = [];
          snapshot.forEach((childSnapshot) => {
            invoicesData.push(childSnapshot.val());
          });
          setInvoices(invoicesData.reverse());
          console.log('Загруженные faktury:', invoicesData);
        } else {
          setInvoices([]); // Обновляем состояние, если данных нет
        }
      } catch (error) {
        console.error('Ошибка при загрузке faktur:', error);
        message.error('Wystąpił błąd przy ładowaniu faktur.');
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, [currentUser, companyId]);

  const getAlertType = (status) => {
    switch (status) {
      case 'zaakceptowany':
        return { type: 'success', color: '#52c41a' };
      case 'odrzucony':
        return { type: 'error', color: '#ff4d4f' };
      case 'w trakcie sprawdzenia':
        return { type: 'warning', color: '#faad14' };
      default:
        return { type: 'info', color: '#1890ff' };
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "20px", marginBottom: "20px" }}>
        <h2>Ostatnie faktury</h2>
        <Button 
          type="primary" 
          onClick={() => navigate('/faktury')}
          style={{ 
            color: 'black', 
            fontWeight: '700',
            backgroundColor: '#e0f64b',
            borderColor: '#e0f64b'
          }}
        >
          Więcej
        </Button>
      </div>
      {loading ? (
        <Spin tip="Ładowanie faktur..." />
      ) : (
        invoices.length > 0 ? (
          <List
            itemLayout="vertical"
            dataSource={invoices}
            renderItem={(item, index) => {
              const { type, color } = getAlertType(item.status);
              return (
                <Alert
                  key={index}
                  type={type}
                  showIcon={false}
                  style={{
                    padding: '12px',
                    border: `1px solid ${color}`,
                    borderRadius: '8px',
                    marginBottom: '12px',
                    backgroundColor: `${color}1a`,
                  }}
                  message={
                    <>
                      <List.Item.Meta
                        title={`Faktura: ${item.numerfaktury}`}
                        description={`Status: ${item.status}`}
                      />
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>Kwota VAT: <strong>{parseFloat(item.vatAmount).toFixed(2)}</strong> zł</p>
                        <p>Kwota brutto: <strong>{parseFloat(item.grossAmount).toFixed(2)}</strong> zł</p>
                      </div>
                    </>
                  }
                />
              );
            }}
          />
        ) : (
          <Alert
            type="info"
            message="Brak faktur"
            description="Nie znaleziono żadnych faktur dla tego użytkownika."
            showIcon
          />
        )
      )}
    </div>
  );
};

export default InvoicesList;
