import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { Modal, Spin, Typography, Steps, Form, Input, Select, Button, Upload, Radio, message, AutoComplete, Row, Col, Divider } from 'antd';
import { useAuth } from './AuthContext';
import { doc, getDoc, setDoc, collection, getDocs, onSnapshot } from 'firebase/firestore';
import { db, storage } from '../firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Lottie from 'lottie-react';
import successAnimation from '../components/lottie/success.json';
import awaitingApprovalAnimation from '../components/lottie/isProcessing.json';
import DocumentSigning from './DocumentSigning.js';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { useCompany } from '../contexts/CompanyContext';

const { Step } = Steps;
const { Option } = Select;

const UserStatusContext = createContext();

export const useUserStatus = () => {
    return useContext(UserStatusContext);
};

export const UserStatusProvider = ({ children }) => {
    const { currentUser } = useAuth();
    const companyData = useCompany();
    const companyId = companyData?.companyId; // Проверяем наличие companyData перед использованием
    const [userStatus, setUserStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [isSdkInitialized, setIsSdkInitialized] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [carOwnership, setCarOwnership] = useState(null);
    const [urzedyOptions, setUrzedyOptions] = useState([]);
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSumsubModalVisible, setIsSumsubModalVisible] = useState(false);
    const [isLoadingSumsub, setIsLoadingSumsub] = useState(false);
    const [countdown, setCountdown] = useState(60);
    
    const intervalIdRef = useRef(null);

    useEffect(() => {
        if (!currentUser || !companyId) return; // Убедимся, что currentUser и companyId существуют

        const userDocRef = doc(db, `companies/${companyId}/users`, currentUser.uid);
        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const status = doc.data().status;
                setUserStatus(status);

                if (status === 'Oczekuje na podpis dokumentów' || status === 'Oczekuje na weryfikacje' || status === 'Dane dodatkowe' || status === 'Wymagana weryfikacja') {
                    setIsModalVisible(true);
                    setCurrentStep(status === 'Dane dodatkowe' ? 1 : 0);
                }
            }
        });

        return () => unsubscribe();
    }, [currentUser, companyId]);

    useEffect(() => {
        if (userStatus === 'Oczekuje na weryfikacje' && isModalVisible) {
            startCountdown();
        }
    }, [userStatus, isModalVisible]);

    const startCountdown = () => {
        intervalIdRef.current = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown <= 1) {
                    clearInterval(intervalIdRef.current);
                    handleLogout();
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    useEffect(() => {
        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, []);

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setIsModalVisible(false);
            message.success('Pomyślnie się wylogowałeś.');
        } catch (error) {
            message.error('Błąd podczas wylogowywania.');
        }
    };

    useEffect(() => {
        const fetchUserStatus = async () => {
            if (currentUser) {
                const userDocRef = doc(db, 'users', currentUser.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    const status = userDoc.data().status;
                    setUserStatus(status);
                    setCurrentStep(status === 'Dane dodatkowe' ? 1 : 0);
                }
            }
            setLoading(false);
        };

        const fetchUrzedy = async () => {
            try {
                const urzedyCollection = collection(db, 'urzedy_skarbowe');
                const urzedySnapshot = await getDocs(urzedyCollection);
                const urzedyList = urzedySnapshot.docs.map(doc => ({ value: doc.data().nazwa }));
                setUrzedyOptions(urzedyList);
            } catch (error) {
                console.error('Error fetching urzedy:', error);
            }
        };

        if (currentUser) {
            fetchUserStatus();
            fetchUrzedy();
        }
    }, [currentUser]);

    useEffect(() => {
        if (isSumsubModalVisible && !isSdkInitialized) {
            initializeSumsubSDK();
        }
    }, [isSumsubModalVisible]);

    const initializeSumsubSDK = async () => {
        if (window.snsWebSdk && currentUser) {
            try {
                setIsLoadingSumsub(true);
                const accessToken = await getAccessToken(currentUser.uid);
                startSumsubSDK(accessToken);
            } catch (error) {
                console.error('Error during initialization:', error);
                setLoadingMessage(error.message);
            } finally {
                setIsLoadingSumsub(false);
            }
        } else {
            console.error('Sumsub SDK не загружен');
            setLoadingMessage('Sumsub SDK не загружен');
        }
    };

    const getAccessToken = async (userId) => {
        const callable = httpsCallable(functions, 'generateSumsubAccessToken');
        const result = await callable({ userId });
        if (!result.data || !result.data.accessToken) {
            throw new Error('No accessToken returned from generateSumsubAccessToken');
        }
        return result.data.accessToken;
    };

    const startSumsubSDK = (accessToken) => {
        const container = document.getElementById('sumsub-container');
        container.style.display = 'block';
        setLoadingMessage('');

        const snsWebSdkInstance = window.snsWebSdk
            .init(accessToken, () => getAccessToken(currentUser.uid))
            .withConf({
                lang: 'pl',
                theme: 'light',
                predefinedCountry: 'POL',
            })
            .withOptions({ addViewportTag: false, adaptIframeHeight: true })
            .on('idCheck.onStepCompleted', async (payload) => {
                await setDoc(doc(db, 'users', currentUser.uid), {
                    status: 'Dane dodatkowe',
                }, { merge: true });

                setUserStatus('Dane dodatkowe');
                setCurrentStep(1);
                setIsSumsubModalVisible(false);
                setIsSdkInitialized(false);
            })
            .on('idCheck.onError', (error) => {
                console.error('onError', error);
                setLoadingMessage('Error during verification.');
                message.error('Wystąpił błąd podczas weryfikacji.');
            })
            .build();

        snsWebSdkInstance.launch('#sumsub-container');
        setIsSdkInitialized(true);
    };

    const handleNextStep = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldsValue();

            message.loading('Proszę czekać, trwa zapisywanie...', 0);

            if (currentStep === 1) {
                await saveCarData(values);
            } else if (currentStep === 2) {
                await saveAdditionalData(values);
            } else if (currentStep === 3) {
                await savePersonalData(values);
            }

            message.success('Dane zapisane pomyślnie.');
            if (currentStep < 3) {
                setCurrentStep(prevStep => prevStep + 1);
            } else {
                handleComplete();
            }
        } catch (error) {
            console.error('Validation failed:', error);
            message.error('Proszę uzupełnić wszystkie pola przed przejściem dalej.');
        } finally {
            message.destroy();
        }
    };

    const handleComplete = async () => {
        try {
            await setDoc(doc(db, 'users', currentUser.uid), { status: 'Oczekuje na weryfikacje' }, { merge: true });
            setIsModalVisible(true);
        } catch (error) {
            message.error('Wystąpił błąd podczas aktualizacji statusu.');
        }
    };

    const handleCarOwnershipChange = async e => {
        const value = e.target.value;
        setCarOwnership(value);

        if (value === 'partner') {
            try {
                await setDoc(doc(db, `companies/${companyId}/${currentUser.uid}/dokumenty`, 'samochod'), {
                    carOwnership: 'Partnera',
                });
                message.success('Wybór pojazdu partnera został zapisany.');
                setCurrentStep(2);
            } catch (error) {
                console.error('Błąd podczas zapisywania wyboru pojazdu partnera:', error);
                message.error('Wystąpił błąd podczas zapisywania wyboru pojazdu partnera.');
            }
        }
    };

    const uploadFile = async (file, fileName) => {
        const storageRef = ref(storage, `companies/${companyId}/${currentUser.uid}/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                snapshot => { },
                error => {
                    console.error('Upload failed:', error);
                    message.error('Wystąpił błąd podczas przesyłania pliku.');
                    reject(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                        resolve(downloadURL);
                    });
                }
            );
        });
    };

    const saveCarData = async (values) => {
        try {
            const carData = carOwnership === 'partner' ? {
                carOwnership: 'Partnera',
            } : {
                carOwnership: 'Własny',
                carMake: values.brand,
                carModel: values.model,
                carYear: values.year,
                carVin: values.vin,
                carRegistrationNumber: values.registrationPlates,
                dowodPrzod: await uploadFile(values.dowodPrzod.file.originFileObj, 'dowodPrzod'),
                dowodTyl: await uploadFile(values.dowodTyl.file.originFileObj, 'dowodTyl'),
                ubezpieczenie: await uploadFile(values.ubezpieczenie.file.originFileObj, 'ubezpieczenie'),
            };

            await setDoc(doc(db, `companies/${companyId}/${currentUser.uid}/dokumenty`, 'samochod'), carData);
        } catch (error) {
            message.error('Wystąpił błąd podczas zapisыwania danych pojazду.');
            throw error;
        }
    };

    const saveAdditionalData = async (values) => {
        try {
            const additionalData = {
                niekaralnosc: await uploadFile(values.niekaralnosc.file.originFileObj, 'niekaralnosc'),
                badaniaLekarskie: await uploadFile(values.badaniaLekarskie.file.originFileObj, 'badaniaLekarskie'),
                badaniaPsychologiczne: await uploadFile(values.badaniaPsychologiczne.file.originFileObj, 'badaniaPsychologiczne'),
                dowodOsobistyPrzod: await uploadFile(values.dowodOsobistyPrzod.file.originFileObj, 'dowodOsobistyPrzod'),
                dowodOsobistyTyl: await uploadFile(values.dowodOsobistyTyl.file.originFileObj, 'dowodOsobistyTyl'),
                zdjecieProfilowe: await uploadFile(values.zdjecieProfilowe.file.originFileObj, 'zdjecieProfilowe'),
            };

            await setDoc(doc(db, `companies/${companyId}/${currentUser.uid}/dokumenty`, 'dane_dodatkowe'), additionalData);
        } catch (error) {
            message.error('Wystąpił błąd podczas zapisывания дополнительных danych.');
            throw error;
        }
    };

    const formatAddress = (street, houseNumber, city, postalCode) => {
        return `${street} ${houseNumber}, ${city}, ${postalCode}`;
    };

    const savePersonalData = async (values) => {
        try {
            const formattedAddress = formatAddress(values.street, values.houseNumber, values.city, values.postalCode);
            const personalData = {
                pesel: values.pesel,
                city: values.city,
                postalCode: values.postalCode,
                street: `${values.street} ${values.houseNumber}`,
                correspondenceAddress: formattedAddress,
                registrationAddress: formattedAddress,
                residenceAddress: formattedAddress,
                citizenship: values.citizenship,
            };

            await setDoc(doc(db, `companies/${companyId}/${currentUser.uid}/dokumenty`, 'dane_personalne'), personalData);
            await setDoc(doc(db, 'users', currentUser.uid), { citizenship: values.citizenship }, { merge: true });
            await setDoc(doc(db, `companies/${companyId}/${currentUser.uid}/dokumenty`, 'samochod'), { citizenship: values.citizenship }, { merge: true });

            const danePodatkowe = {
                rachunekBankowy: values.bankAccount,
                urzadSkarbowy: values.taxOffice,
            };

            await setDoc(doc(db, `companies/${companyId}/${currentUser.uid}/dokumenty`, 'dane_podatkowe'), danePodatkowe);

            message.success('Dane osobowe i podatkowe zapisane pomyślnie.');
        } catch (error) {
            message.error('Wystąpił błąd podczas zapisывания danych osobowych.');
            throw error;
        }
    };

    const getCurrentStep = () => {
        switch (userStatus) {
            case 'Wymagana weryfikacja':
                return 0;
            case 'Dane dodatkowe':
                return 1;
            default:
                return 0;
        }
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <Typography.Title level={4}>Weryfikacja KYC</Typography.Title>
                        <Typography.Text>
                            Proszę przejść weryfikację tożsamości, aby kontynuować.
                        </Typography.Text>
                        <div style={{ marginTop: '16px' }}>
                            <Button type="primary" onClick={() => setIsSumsubModalVisible(true)}>
                                Rozpocznij weryfikację KYC
                            </Button>
                        </div>
                        <Spin spinning={loading} tip={loadingMessage} />
                    </>
                );
            case 1:
                return (
                    <>
                        <Typography.Title level={4}>Proszę wybrać, czy będziesz korzystać z własnego pojazdu, czy pojazdu partnera.</Typography.Title>
                        <Radio.Group onChange={handleCarOwnershipChange} value={carOwnership}>
                            <Radio value="own">Własny pojazd</Radio>
                            <Radio value="partner">Pojazd partnera</Radio>
                        </Radio.Group>

                        {carOwnership === 'own' && (
                            <Form form={form} layout="vertical">
                                <Form.Item label="Marka" name="brand" rules={[{ required: true, message: 'Proszę wprowadzić markę' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Model" name="model" rules={[{ required: true, message: 'Proszę wprowadzić model' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Rok" name="year" rules={[{ required: true, message: 'Proszę wprowadzić rok' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="VIN" name="vin" rules={[{ required: true, message: 'Proszę wprowadzić VIN' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Tablice rejestracyjne" name="registrationPlates" rules={[{ required: true, message: 'Proszę wprowadzić tablice rejestracyjne' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Dowód Rejestracyjny Przód" name="dowodPrzod" rules={[{ required: true, message: 'Proszę dodać dowód przód' }]}>
                                    <Upload>
                                        <Button>Wybierz plik</Button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item label="Dowód Rejestracyjny Tył" name="dowodTyl" rules={[{ required: true, message: 'Proszę dodać dowód tył' }]}>
                                    <Upload>
                                        <Button>Wybierz plik</Button>
                                    </Upload>
                                </Form.Item>
                                <Form.Item label="Ubezpieczenie" name="ubezpieczenie" rules={[{ required: true, message: 'Proszę dodać ubezpieczenie' }]}>
                                    <Upload>
                                        <Button>Wybierz plik</Button>
                                    </Upload>
                                </Form.Item>
                            </Form>
                        )}

                        {carOwnership === 'own' && (
                            <Button type="primary" onClick={handleNextStep}>
                                Dalej
                            </Button>
                        )}
                    </>
                );
            case 2:
                return (
                    <>
                        <Typography.Title level={4}>Dodatkowe dane</Typography.Title>
                        <Form form={form} layout="vertical">
                            <Form.Item label="Niekaralnosć" name="niekaralnosc" rules={[{ required: true, message: 'Proszę dodać niekaralność' }]}>
                                <Upload>
                                    <Button>Wybierz plik</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item label="Badania lekarskie" name="badaniaLekarskie" rules={[{ required: true, message: 'Proszę dodać badania lekarskie' }]}>
                                <Upload>
                                    <Button>Wybierz plik</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item label="Badania psychologiczne" name="badaniaPsychologiczne" rules={[{ required: true, message: 'Proszę dodać badania psychologiczne' }]}>
                                <Upload>
                                    <Button>Wybierz plik</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item label="Dowód osobisty przód" name="dowodOsobistyPrzod" rules={[{ required: true, message: 'Proszę dodać dowód osobisty przód' }]}>
                                <Upload>
                                    <Button>Wybierz plik</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item label="Dowód osobisty tył" name="dowodOsobistyTyl" rules={[{ required: true, message: 'Proszę dodać dowód osobisty tył' }]}>
                                <Upload>
                                    <Button>Wybierz plik</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item label="Zdjęcie profilowe" name="zdjecieProfilowe" rules={[{ required: true, message: 'Proszę dodać zdjęcie profilowe' }]}>
                                <Upload>
                                    <Button>Wybierz plik</Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                        <Button type="primary" onClick={handleNextStep}>
                            Dalej
                        </Button>
                    </>
                );
            case 3:
                return (
                    <>
                        <Typography.Title level={4}>Dane osobowe</Typography.Title>
                        <Form form={form} layout="vertical">
                            <Divider orientation="left" plain>
                                Dane rozliczeniowe
                            </Divider>
                            <Form.Item
                                label="Konto bankowe"
                                name="bankAccount"
                                rules={[
                                    { required: true, message: 'Proszę wprowadzić konto bankowe' },
                                    { len: 26, message: 'Konto bankowe musi zawierać dokładnie 26 cyfr' },
                                ]}
                            >
                                <Input placeholder="Wprowadź numer konta bankowego (26 cyfr)" />
                            </Form.Item>
                            <Form.Item
                                label="Urząd skarbowy"
                                name="taxOffice"
                                rules={[{ required: true, message: 'Proszę wybrać urząd skarbowy' }]}
                            >
                                <AutoComplete
                                    options={urzedyOptions}
                                    placeholder="Wybierz urząd skarbowy"
                                    filterOption={(inputValue, option) =>
                                        option.value.toLowerCase().includes(inputValue.toLowerCase())
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Okres rozliczeniowy"
                                name="settlementPeriod"
                                rules={[{ required: true, message: 'Proszę wybrać okres rozliczeniowy' }]}
                            >
                                <Select placeholder="Wybierz okres rozliczeniowy">
                                    <Option value="weekly">Co tydzień</Option>
                                    <Option value="biweekly">Co dwa tygodnie</Option>
                                    <Option value="monthly">Co miesiąc</Option>
                                </Select>
                            </Form.Item>
                            <Divider orientation="left" plain>
                                Dane personalne
                            </Divider>
                            <Form.Item
                                label="PESEL"
                                name="pesel"
                                rules={[
                                    { required: true, message: 'Proszę wprowadzić PESEL' },
                                    { len: 11, message: 'PESEL musi zawierać dokładnie 11 cyfr' },
                                ]}
                            >
                                <Input placeholder="Wprowadź PESEL (11 cyfr)" />
                            </Form.Item>
                            <Form.Item
                                label="Obywatelstwo"
                                name="citizenship"
                                rules={[{ required: true, message: 'Proszę wprowadzić obywatelstwo' }]}
                            >
                                <Input placeholder="Wprowadź obywatelstwo" />
                            </Form.Item>
                            <Divider orientation="left" plain>
                                Adres zamieszkania
                            </Divider>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Ulica"
                                        name="street"
                                        rules={[{ required: true, message: 'Proszę wprowadzić ulicę' }]}
                                    >
                                        <Input placeholder="Wprowadź nazwę улицы" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Numer domu/mieszkanie"
                                        name="houseNumber"
                                        rules={[{ required: true, message: 'Proszę wprowadzić numer domu/mieszkanie' }]}
                                    >
                                        <Input placeholder="Wprowadź numer domu/mieszkanie" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Miasto"
                                        name="city"
                                        rules={[{ required: true, message: 'Proszę wprowadzić miasto' }]}
                                    >
                                        <Input placeholder="Wprowadź miasto" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Kod pocztowy"
                                        name="postalCode"
                                        rules={[
                                            { required: true, message: 'Proszę wprowadzić kod pocztowy' },
                                            {
                                                pattern: /^[0-9]{2}-[0-9]{3}$/,
                                                message: 'Kod pocztowy musi być в формате 01-111',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Wprowadź kod pocztowy (np. 01-111)" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <Button type="primary" onClick={handleNextStep}>
                            Zakończ
                        </Button>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <UserStatusContext.Provider value={{ userStatus }}>
            {!loading && children}
            {(userStatus === 'Wymagana weryfikacja' || userStatus === 'Dane dodatkowe') && isModalVisible && (
                <Modal
                    visible={true}
                    closable={false}
                    footer={null}
                    maskClosable={false}
                    keyboard={false}
                >
                    <Steps
                        size="small"
                        current={getCurrentStep()}
                        style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}
                    >
                        <Step title="Weryfikacja" />
                        <Step title="Dane dodatkowe" />
                    </Steps>

                    {renderStepContent()}
                </Modal>
            )}
            {isSuccess && (
                <Modal
                    visible={true}
                    closable={false}
                    footer={null}
                    maskClosable={false}
                    keyboard={false}
                >
                    <Lottie
                        animationData={successAnimation}
                        loop={false}
                        autoplay
                        height={200}
                        width={200}
                    />
                    <Typography.Title level={4} style={{ textAlign: 'center', marginTop: '20px' }}>
                        Wszystkie dane zapisane pomyślnie. Oczekuj na zatwierdzenie przez administratora.
                    </Typography.Title>
                </Modal>
            )}
            {userStatus === 'Oczekuje na weryfikacje' && isModalVisible && (
                <Modal
                    visible={true}
                    closable={false}
                    footer={null}
                    maskClosable={false}
                    keyboard={false}
                >
                    <Lottie
                        animationData={awaitingApprovalAnimation}
                        loop={true}
                        autoplay
                        height={200}
                        width={200}
                    />
                    <Typography.Title level={4} style={{ textAlign: 'center', marginTop: '20px' }}>
                        Twoje konto oczekuje na zatwierdzenie przez администратора. Proszę czekać.
                    </Typography.Title>
                    <Typography.Text style={{ textAlign: 'center', display: 'block', marginTop: '10px' }}>
                        Automatyczne wyjście po {countdown} sekundach.
                    </Typography.Text>
                    <Button
                        type="primary"
                        style={{ marginTop: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                        onClick={handleLogout}
                    >
                        Wyloguj się
                    </Button>
                </Modal>
            )}

            {userStatus === 'Oczekuje na podpis dokumentów' && isModalVisible && (
                <DocumentSigning
                    visible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                />
            )}
            {userStatus === 'Wymagana weryfikcja' && isModalVisible && (
                <Modal
                    visible={true}
                    closable={false}
                    footer={null}
                    maskClosable={false}
                    keyboard={false}
                >
                    <Lottie
                        animationData={awaitingApprovalAnimation}
                        loop={true}
                        autoplay
                        height={200}
                        width={200}
                    />
                    <Typography.Title level={4} style={{ textAlign: 'center', marginTop: '20px' }}>
                        Trwa generowanie dokumentów. Proszę czekać.
                    </Typography.Title>
                </Modal>
            )}

            {/* Sumsub Modal */}
            <Modal
                visible={isSumsubModalVisible}
                onCancel={() => setIsSumsubModalVisible(false)}
                footer={null}
                maskClosable={false}
                keyboard={false}
                style={{ minHeight: '500px', width: 'auto', height: 'auto' }}
            >
                <Spin spinning={isLoadingSumsub}>
                    <div id="sumsub-container" style={{ width: '100%', minHeight: '500px' }}></div>
                </Spin>
            </Modal>

        </UserStatusContext.Provider>
    );
};

export default UserStatusProvider;
