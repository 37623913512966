import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getDatabase, ref, onValue, off, set } from 'firebase/database';
import { Select, Collapse, Button, Alert, Modal, Input } from 'antd';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { useCompany } from '../contexts/CompanyContext';

const { Option } = Select;
const { Panel } = Collapse;

const Rozliczenia = () => {
  const [driverId, setDriverId] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [summaryData, setSummaryData] = useState({});
  const [totalNetto, setTotalNetto] = useState(0);
  const [appIncomes, setAppIncomes] = useState({});
  const [chartData, setChartData] = useState([]);
  const [incomeGoal, setIncomeGoal] = useState(0);  // Состояние для цели по доходу
  const [isModalVisible, setIsModalVisible] = useState(false);  // Состояние модального окна
  const [tempIncomeGoal, setTempIncomeGoal] = useState(incomeGoal);  // Временное хранение цели

  const auth = getAuth();
  const db = getDatabase();
  const companyData = useCompany();
  const companyId = companyData?.companyId;

  useEffect(() => {
    const fetchDriverId = async () => {
      const user = auth.currentUser;
      if (user && companyId) {
        const firestore = getFirestore();
        const userRef = doc(firestore, `companies/${companyId}/users/${user.uid}`);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setDriverId(userDoc.data().driverId);
        } else {
          console.error('Dokument użytkownika nie istnieje.');
        }
      }
    };

    fetchDriverId();
  }, [auth, companyId]);

  useEffect(() => {
    if (driverId && companyId) {
      const driversRef = ref(db, `companies/${companyId}/drivers/${driverId}/weeks`);

      const handleWeeksSnapshot = (snapshot) => {
        const weeksData = snapshot.val();
        if (weeksData) {
          const sortedWeeks = Object.entries(weeksData).sort(([aKey], [bKey]) => {
            const [aWeek, aYear] = aKey.split('-').map(Number);
            const [bWeek, bYear] = bKey.split('-').map(Number);
            return aYear !== bYear ? bYear - aYear : bWeek - aWeek;
          });
          setWeeks(sortedWeeks);
          if (sortedWeeks.length > 0) {
            const latestWeek = sortedWeeks[0][0];
            setSelectedWeek(latestWeek);
            displayUserData(driverId, latestWeek);
            fetchDriverSummaryData(driverId, latestWeek);
          }
        }
      };

      onValue(driversRef, handleWeeksSnapshot);

      return () => {
        off(driversRef, 'value', handleWeeksSnapshot);
      };
    }
  }, [driverId, companyId, db]);

  const displayUserData = (driverId, weekId) => {
    if (!weekId || !companyId) return;
    const weekRef = ref(db, `companies/${companyId}/drivers/${driverId}/weeks/${weekId}/apps`);

    const handleWeekDataSnapshot = (snapshot) => {
      const weekData = snapshot.val();
      let totalNettoSum = 0;
      const incomes = {};

      if (weekData) {
        for (let appId in weekData) {
          const netto = calculateNetto(weekData[appId]);
          totalNettoSum += netto;
          incomes[appId] = netto;
        }
      }
      setTotalNetto(totalNettoSum);
      setAppIncomes(incomes);
    };

    onValue(weekRef, handleWeekDataSnapshot);

    return () => {
      off(weekRef, 'value', handleWeekDataSnapshot);
    };
  };

  const calculateNetto = (appData) => {
    const fields = ['kursy', 'przychod_dodatkowy', 'commission'];
    return fields.reduce((sum, field) => sum + (Number(appData[field]) || 0), 0);
  };

  const fetchDriverSummaryData = (driverId, weekId) => {
    if (!weekId || !companyId) return;
    const refPath = `companies/${companyId}/drivers/${driverId}/weeks/${weekId}/summary`;
    const summaryRef = ref(db, refPath);

    const handleSummarySnapshot = (snapshot) => {
      const summary = snapshot.val();
      if (summary) {
        setSummaryData(summary);
      } else {
        console.error('Dane podsumowujące nie istnieją.');
      }
    };

    onValue(summaryRef, handleSummarySnapshot);

    return () => {
      off(summaryRef, 'value', handleSummarySnapshot);
    };
  };

  useEffect(() => {
    const income = calculateTotalIncome();
    const expenses = calculateTotalExpenses();

    console.log('Przychody:', income);
    console.log('Wydatki:', expenses);

    setChartData([
      { name: 'Przychody', value: income },
      { name: 'Wydatki', value: expenses }
    ]);
  }, [appIncomes, summaryData]);

  useEffect(() => {
    if (driverId && companyId) {
      const goalRef = ref(db, `companies/${companyId}/drivers/${driverId}/goal`);
    
      const handleGoalSnapshot = (snapshot) => {
        const goal = snapshot.val();
        if (goal !== null) {
          setIncomeGoal(goal);
        }
      };
    
      onValue(goalRef, handleGoalSnapshot);
    
      return () => {
        off(goalRef, 'value', handleGoalSnapshot);
      };
    }
  }, [driverId, companyId]);

  const saveIncomeGoal = async (goal) => {
    if (driverId && companyId) {
      const goalRef = ref(db, `companies/${companyId}/drivers/${driverId}/goal`);
      try {
        await set(goalRef, goal);
        console.log('Цель успешно сохранена:', goal);
      } catch (error) {
        console.error('Ошибка при сохранении цели:', error);
      }
    }
  };
  

  const showModal = () => {
    setTempIncomeGoal(incomeGoal);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await saveIncomeGoal(tempIncomeGoal); // Сохранение цели с использованием временного значения
    setIncomeGoal(tempIncomeGoal); // Обновление состояния после сохранения
    setIsModalVisible(false);
  };
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleWeekChange = (value) => {
    setSelectedWeek(value);
    if (driverId && value) {
      displayUserData(driverId, value);
      fetchDriverSummaryData(driverId, value);
    }
  };

  const formatCurrency = (value) => {
    const numericValue = Math.abs(Number(value));
    return !isNaN(numericValue) ? numericValue.toFixed(2) + ' zł' : '0.00 zł';
  };

  const calculateTotalIncome = () => {
    return Object.values(appIncomes).reduce((sum, value) => sum + value, summaryData.bonusPartnera || 0) + (summaryData.vat_bonus || 0);
  };

  const calculateTotalExpenses = () => {
    const totalExpenses =
      (summaryData.vat_dodatkowy || 0) +
      (summaryData.vat_przejazdy || 0) +
      (summaryData.wynajem || 0) +
      (summaryData.partner || 0) +
      (summaryData.zus || 0) +
      (summaryData.inne || 0);

    return Number(totalExpenses);
  };

  const renderSummaryItem = (label, value) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0' }}>
      <span><strong>{capitalizeFirstLetter(label)}:</strong></span>
      <span>{formatCurrency(value)}</span>
    </div>
  );
  
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const renderGoalComparison = () => {
    const totalIncome = calculateTotalIncome();
    const difference = incomeGoal - summaryData.total;
    const message = difference > 0 
      ? `Pozostało do celu: ${formatCurrency(difference)}`
      : 'Cel osiągnięty!';

    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Alert
          message={message}
          type={difference > 0 ? 'warning' : 'success'}
          showIcon
          style={{ flexGrow: 1, marginRight: '10px', borderRadius: '10px' }}
        />
        <Button type="primary" onClick={showModal} style={{ padding: "20px", color: 'black'}}>
        Wyznacz cel
        </Button>
      </div>
    );
  };
  
  return (
    <div style={{ maxWidth: '100%', margin: '0 auto' }}>
      <div style={{ marginBottom: '40px', textAlign: 'center', width: '100%' }}>
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="100%"
              startAngle={180}
              endAngle={0}
              innerRadius={60}
              outerRadius={100}
              paddingAngle={15}
              dataKey="value"
            >
              <Cell key="income" fill="#e0f64b" />
              <Cell key="expenses" fill="black" />
            </Pie>
            <Tooltip />
            <Legend verticalAlign="top" align="center" />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <Select
        style={{ width: '100%', marginBottom: '20px', borderRadius: '16px' }}
        onChange={handleWeekChange}
        value={selectedWeek}
        disabled={!weeks.length}
      >
        {weeks.map(([week]) => (
          <Option key={week} value={week}>
            Tydzień {week.split('-')[0]} {week.split('-')[1]}
          </Option>
        ))}
      </Select>
      
      <div style={{  alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between', width: '100%' }}>
        <Alert
          message={`Do wypłaty: ${formatCurrency(summaryData.total)}`}
          type="success"
          showIcon
          style={{ borderRadius: '10px' }}
        />
      </div>
      
      {renderGoalComparison()}

      <Collapse defaultActiveKey={['1', '2']} style={{ borderRadius: '10px', overflow: 'hidden' }}>
        <Panel
          header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Przychody:</span>
              <span>{formatCurrency(calculateTotalIncome())}</span>
            </div>
          }
          key="1"
        >
          {Object.entries(appIncomes).map(([appId, netto]) => (
            renderSummaryItem(appId, netto)
          ))}
          {renderSummaryItem('Bonus Partnera', summaryData.bonusPartnera)}
          {renderSummaryItem('Zwrot VAT', summaryData.vat_bonus)}
        </Panel>
        <Panel
          header={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Wydatki:</span>
              <span>{formatCurrency(calculateTotalExpenses())}</span>
            </div>
          }
          key="2"
        >
          {renderSummaryItem('Należny podatek VAT', (summaryData.vat_dodatkowy || 0) + (summaryData.vat_przejazdy || 0))}
          {renderSummaryItem('Pobrana gotówka', summaryData.gotowka)}
          {renderSummaryItem('Wynajem', summaryData.wynajem)}
          {renderSummaryItem('Partner', summaryData.partner)}
          {renderSummaryItem('Ubezpieczenie społeczne i zdrowotne', summaryData.zus)}
          {renderSummaryItem('Inne wydatki', summaryData.inne)}
        </Panel>
        <Panel header="Dodatkowe" key="3">
          {renderSummaryItem('Umowa najmu', summaryData.umowa_najmu)}
          {renderSummaryItem('Umowa zlecenie', summaryData.kosztyUZ)}
          {renderSummaryItem('Zwrot kosztów (paliwo)', summaryData.zwrot_kosztow)}
          {renderSummaryItem('Podatek do zapłaty', summaryData.podatek_do_zaplaty)}
        </Panel>
      </Collapse>

      <Modal
        title="Wyznacz cel finansowy"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          type="number"
          value={tempIncomeGoal}
          onChange={(e) => setTempIncomeGoal(Number(e.target.value))}
          placeholder="Określ swój cel finansowy"
        />
      </Modal>
    </div>
  );
};

export default Rozliczenia;
