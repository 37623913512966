import React from 'react';
import { Form, Input, Button, message, Row, Col, Typography, Space, Divider } from 'antd';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db, requestForToken } from '../firebaseConfig'; // Импорт необходимых сервисов
import { doc, setDoc } from 'firebase/firestore';
import { GoogleOutlined } from '@ant-design/icons';

import Header from '../components/Header'; // Импортируем компонент Header

import './Auth.css';

const { Title, Text } = Typography;

const Login = () => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const { username, password } = values;

    message.loading({ content: 'Logowanie...', key: 'login' });

    try {
      const userCredential = await signInWithEmailAndPassword(auth, username, password);
      const user = userCredential.user;

      const token = await requestForToken();

      if (token) {
        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, { fcmToken: token }, { merge: true });
      }

      message.success({ content: `Pomyślnie zalogowano! Użytkownik: ${user.email}`, key: 'login', duration: 2 });
      navigate('/');
    } catch (error) {
      message.error({ content: 'Nie udało się zalogować. Sprawdź swoje dane logowania i spróbuj ponownie.', key: 'login', duration: 2 });
      console.error('Failed to login:', error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const token = await requestForToken();
      if (token) {
        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, { fcmToken: token }, { merge: true });
      }

      message.success({ content: `Pomyślnie zalogowano przez Google! Użytkownik: ${user.email}`, key: 'login', duration: 2 });
      navigate('/');
    } catch (error) {
      console.error('Error signing in with Google: ', error);
      message.error({ content: 'Nie udało się zalogować przez Google.', key: 'login', duration: 2 });
    }
  };

  const navigateToRegister = () => {
    navigate('/register');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      <Header /> {/* Добавляем Header здесь */}
      <Row style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
        <Col xs={24} md={12} style={{ background: 'white', padding: '20px', maxWidth: '', width: '100%' }}>
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{
              maxWidth: '100%',
              backgroundColor: '#fff',
              borderRadius: '16px',
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Title level={2} style={{ textAlign: 'left', marginBottom: '24px' }}>Logowanie do Panelu Kierowcy</Title>

            <Form.Item
              label={<span className="custom-label">Adres email</span>}
              name="username"
              rules={[{ required: true, message: 'Proszę wprowadzić nazwę użytkownika!' }]}
            >
              <Input
                placeholder="Wprowadź adres email"
                style={{ padding: '16px', borderRadius: '16px' }}
              />
            </Form.Item>

            <Form.Item
              label={<span className="custom-label">Hasło</span>}
              name="password"
              rules={[{ required: true, message: 'Proszę wprowadzić hasło!' }]}
            >
              <Input.Password
                placeholder="Wprowadź hasło"
                style={{ padding: '16px', borderRadius: '16px' }}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }}>
              <button
                type="submit"
                className="custom-button"
                style={{
                  width: '100%',
                  color: 'black',
                  borderRadius: '16px',
                  padding: '16px',
                  backgroundColor: '#e0f64b',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                Zaloguj się
              </button>
            </Form.Item>

            <Divider plain style={{ color: 'grey' }}>lub</Divider>

            <Form.Item wrapperCol={{ span: 24 }}>
              <button
                type="button" // Изменил type на "button", чтобы предотвратить submit формы
                className="custom-button"
                style={{
                  width: '100%',
                  color: 'black',
                  borderRadius: '16px',
                  padding: '16px',
                  border: '1px solid #d9d9d9',
                  background: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClick={handleGoogleSignIn} // Обработчик для входа через Google
              >
                <GoogleOutlined style={{ marginRight: '8px' }} />
                Zaloguj się z Google
              </button>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Space direction="vertical" size="middle" style={{ width: '100%', textAlign: 'center' }}>
                <Text>Nie masz konta?<Button type="link" onClick={navigateToRegister}>Zarejestruj się</Button></Text>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
