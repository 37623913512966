import React, { useState } from 'react';
import { Form, Input, Button, Typography, message, Row, Col, Space, Divider } from 'antd';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { doc, setDoc, query, where, collection, getDocs, serverTimestamp } from 'firebase/firestore';
import Header from '../components/Header';
import './Auth.css';
import { useCompany } from '../contexts/CompanyContext';
import { GoogleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Register = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const companyData = useCompany();

  if (!companyData || !companyData.companyId) {
    message.error('Nie znaleziono danych firmy. Spróbuj ponownie później.');
    return null; // Не рендерим компонент, если нет companyId
  }

  const companyId = companyData.companyId;

  const translateFirebaseError = (errorCode) => {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'Ten adres e-mail jest już używany.';
      case 'auth/invalid-email':
        return 'Nieprawidłowy adres e-mail.';
      case 'auth/weak-password':
        return 'Hasło jest zbyt słabe. Wybierz silniejsze hasło.';
      case 'auth/operation-not-allowed':
        return 'Operacja niedozwolona. Skontaktuj się z administratorem.';
      case 'auth/user-disabled':
        return 'Konto użytkownika jest zablokowane.';
      default:
        return 'Wystąpił nieznany błąd. Spróbuj ponownie później.';
    }
  };

  const createDriverId = (firstName, lastName) => {
    const polishToEnglish = {
      'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z', 'ż': 'z',
      'Ą': 'A', 'Ć': 'C', 'Ę': 'E', 'Ł': 'L', 'Ń': 'N', 'Ó': 'O', 'Ś': 'S', 'Ź': 'Z', 'Ż': 'Z'
    };

    let driverId = `${firstName} ${lastName}`.replace(/[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, char => polishToEnglish[char]);

    driverId = driverId.replace(/\s+/g, ' ').trim().toLowerCase();
    driverId = driverId.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return driverId;
  };

  const handleRegister = async (values) => {
    const { email, firstName, lastName, phoneNumber } = values;

    if (password !== confirmPassword) {
      message.error('Hasła nie są identyczne.');
      return;
    }

    try {
      const driverId = createDriverId(firstName, lastName);

      // Проверка на существование пользователя с таким же driverId
      const usersQuery = query(
        collection(db, `companies/${companyId}/users`),
        where('driverId', '==', driverId)
      );
      const querySnapshot = await getDocs(usersQuery);

      if (!querySnapshot.empty) {
        message.error('Użytkownik z takim ID kierowcy już istnieje.');
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;

      const userDocRef = doc(db, `companies/${companyId}/users`, uid);

      await setDoc(userDocRef, {
        email,
        firstName,
        lastName,
        driverId,
        phoneNumber,
        role: 'user',
        registeredAt: serverTimestamp(),
        status: 'Wymagana weryfikacja'
      });

      message.success('Zarejestrowano pomyślnie! Przekierowanie na stronę logowania...');
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      const errorMessage = translateFirebaseError(error.code);
      message.error(`Błąd podczas rejestracji: ${errorMessage}`);
      console.error('Error signing up:', error);
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const nameParts = user.displayName.split(' ');
      const firstName = nameParts[0];
      const lastName = nameParts.length > 1 ? nameParts[1] : '';
      const email = user.email;
      const phoneNumber = user.phoneNumber || '';
      const uid = user.uid;
      const driverId = createDriverId(firstName, lastName);

      // Проверка на существование пользователя с таким же driverId
      const usersQuery = query(
        collection(db, `companies/${companyId}/users`),
        where('driverId', '==', driverId)
      );
      const querySnapshot = await getDocs(usersQuery);

      if (!querySnapshot.empty) {
        message.error('Użytkownik z takim ID kierowcy już istnieje.');
        return;
      }

      const userDocRef = doc(db, `companies/${companyId}/users`, uid);

      await setDoc(userDocRef, {
        email,
        firstName,
        lastName,
        driverId,
        phoneNumber,
        role: 'user',
        registeredAt: serverTimestamp(),
        status: 'Wymagana weryfikacja'
      });

      message.success('Zarejestrowano pomyślnie! Przekierowanie na stronę logowania...');
      setTimeout(() => {
        navigate('/zaloguj-sie');
      }, 3000);
    } catch (error) {
      const errorMessage = translateFirebaseError(error.code);
      message.error(`Błąd podczas rejestracji: ${errorMessage}`);
      console.error('Error signing up with Google:', error);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      <Header />
      <Row style={{ flex: 1, justifyContent: 'center', alignItems: 'center', width: '100vw' }}>
        <Col xs={24} md={12} style={{ background: 'white', padding: '20px', width: '100%' }}>
          <Form
            name="register"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{
              maxWidth: '100%',
              backgroundColor: '#fff',
              borderRadius: '16px',
            }}
            initialValues={{ remember: true }}
            onFinish={handleRegister}
            autoComplete="off"
          >
            <Title level={2} style={{ textAlign: 'left', marginBottom: '24px' }}>Rejestracja</Title>

            <Form.Item
              label={<span className="custom-label">Adres email</span>}
              name="email"
              rules={[{ required: true, message: 'Proszę wprowadzić adres email!' }]}
            >
              <Input
                placeholder="Wprowadź adres email"
                style={{ padding: '16px', borderRadius: '16px' }}
              />
            </Form.Item>
            
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<span className="custom-label">Imię</span>}
                  name="firstName"
                  rules={[{ required: true, message: 'Proszę wprowadzić imię!' }]}
                >
                  <Input
                    placeholder="Wprowadź imię"
                    style={{ padding: '16px', borderRadius: '16px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span className="custom-label">Nazwisko</span>}
                  name="lastName"
                  rules={[{ required: true, message: 'Proszę wprowadzić nazwisko!' }]}
                >
                  <Input
                    placeholder="Wprowadź nazwisko"
                    style={{ padding: '16px', borderRadius: '16px' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<span className="custom-label">Hasło</span>}
                  name="password"
                  rules={[{ required: true, message: 'Proszę wprowadzić hasło!' }]}
                >
                  <Input.Password
                    placeholder="Wprowadź hasło"
                    style={{ padding: '16px', borderRadius: '16px' }}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<span className="custom-label">Potwierdź hasło</span>}
                  name="confirmPassword"
                  rules={[{ required: true, message: 'Proszę potwierdzić hasło!' }]}
                >
                  <Input.Password
                    placeholder="Potwierdź hasło"
                    style={{ padding: '16px', borderRadius: '16px' }}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label={<span className="custom-label">Numer telefonu</span>}
              name="phoneNumber"
              rules={[{ required: true, message: 'Proszę wprowadzić numer telefonu!' }]}
            >
              <Input
                addonBefore={<div className="custom-addon">+48</div>}
                placeholder="Wprowadź numer telefonu"
                className="custom-input"
                style={{ padding: '16px', borderRadius: '16px' }}
              />
            </Form.Item>
            
            <Form.Item wrapperCol={{ span: 24 }}>
              <button
                type="submit"
                className="custom-button"
                style={{
                  width: '100%',
                  color: 'black',
                  borderRadius: '16px',
                  padding: '16px',
                  backgroundColor: '#e0f64b',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                Zarejestruj się
              </button>
            </Form.Item>

            <Divider plain style={{ color: 'grey' }}>lub</Divider>

            <Form.Item wrapperCol={{ span: 24 }}>
              <button
                type="button"
                className="custom-button"
                style={{
                  width: '100%',
                  color: 'black',
                  borderRadius: '16px',
                  padding: '16px',
                  border: '1px solid #d9d9d9',
                  background: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClick={handleGoogleSignUp}
              >
                <GoogleOutlined style={{ marginRight: '8px' }} />
                Zarejestruj się z Google
              </button>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <Space direction="vertical" size="middle" style={{ width: '100%', textAlign: 'center' }}>
                <Text>Masz już konto? <Button type="link" onClick={() => navigate('/login')}>Zaloguj się</Button></Text>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
