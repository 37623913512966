import React, { useState, useEffect } from 'react';
import { Layout, Badge, Dropdown, Menu, message, Modal, Button, List } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirestore, collection, onSnapshot, doc, updateDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useCompany } from '../contexts/CompanyContext'; // Import the company context
import './Header.css';

const { Header } = Layout;

const AppHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { companyId } = useCompany() || {}; // Use safe destructuring
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [allReadNotifications, setAllReadNotifications] = useState([]);
  const [menuVisible, setMenuVisible] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user || !companyId) return;

      const db = getFirestore();

      try {
        // Fetch company data
        const companyDocRef = doc(db, `companies`, companyId);
        const companyDoc = await getDoc(companyDocRef);
        if (companyDoc.exists()) {
          const companyData = companyDoc.data() || {};
          setCompanyLogo(companyData.logoUrl || null);
          setCompanyName(companyData.name || '');
        } else {
          console.error("Company document does not exist.");
        }

        // Fetch notifications
        const notificationsRef = collection(db, `users/${user.uid}/notifications`);
        const unsubscribe = onSnapshot(notificationsRef, (snapshot) => {
          if (!snapshot.empty) {
            const fetchedNotifications = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data()?.createdAt?.toDate() || new Date(),
            }));

            fetchedNotifications.sort((a, b) => b.createdAt - a.createdAt);

            setNotifications(fetchedNotifications);
            setUnreadCount(fetchedNotifications.filter(notification => !notification.isRead).length);
          } else {
            setNotifications([]);
            setUnreadCount(0);
          }
        }, (error) => {
          message.error("Błąd podczas ładowania powiadomień.");
          console.error("Error fetching notifications: ", error);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching data: ", error);
        message.error("Błąd podczas ładowania danych.");
      }
    };

    fetchData();
  }, [companyId]);

  const handleLogoClick = () => {
    navigate('/');
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/rozliczenia':
        return 'Rozliczenia';
      case '/faktury':
        return 'Faktury';
      case '/samochod':
        return 'Samochod';
      case '/konto':
        return 'Konto';
      case '/':
        return 'Strona główna';
      default:
        return 'Panel Fleetify';
    }
  };

  const handleMenuVisibleChange = (visible) => {
    setMenuVisible(visible);
    if (!visible) {
      handleMarkAsRead();
    }
  };

  const handleMarkAsRead = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) return;

    const db = getFirestore();
    const unreadNotifications = notifications.filter(notification => !notification.isRead);

    try {
      const updatePromises = unreadNotifications.map(notification =>
        updateDoc(doc(db, `users/${user.uid}/notifications`, notification.id), {
          isRead: true,
        })
      );

      await Promise.all(updatePromises);

      const updatedNotifications = notifications.map(notification =>
        notification.isRead ? notification : { ...notification, isRead: true }
      );
      setNotifications(updatedNotifications);
      setUnreadCount(0);
    } catch (error) {
      message.error("Błąd podczas aktualizacji powiadomień.");
      console.error("Error updating notifications: ", error);
    }
  };

  const showModal = () => {
    setAllReadNotifications(notifications.filter(notification => notification.isRead));
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const formatDate = (date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();

    if (isToday) {
      return `Dzisiaj o ${date.toLocaleTimeString('pl-PL', {
        hour: '2-digit',
        minute: '2-digit',
      })}`;
    } else if (isYesterday) {
      return `Wczoraj o ${date.toLocaleTimeString('pl-PL', {
        hour: '2-digit',
        minute: '2-digit',
      })}`;
    } else {
      return date.toLocaleString('pl-PL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
  };

  const notificationMenu = (
    <Menu > 
      {notifications.length > 0 ? (
        <>
          <Menu.ItemGroup title="Nie przeczytano">
            {notifications.filter(notification => !notification.isRead).length > 0 ? (
              notifications.filter(notification => !notification.isRead).map(notification => (
                <Menu.Item key={notification.id} className="notification-item">
                  <div className="notification-title">{notification.title || 'Bez tytułu'}</div>
                  <div className="notification-text">{notification.text || 'Bez treści'}</div>
                  <div className="notification-date">{formatDate(notification.createdAt)}</div>
                </Menu.Item>
              ))
            ) : (
              <Menu.Item className="notification-item">Brak nieprzeczytanych powiadomień</Menu.Item>
            )}
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.ItemGroup title="Przeczytano">
            {notifications.filter(notification => notification.isRead).slice(0, 5).map(notification => (
              <Menu.Item key={notification.id} className="notification-item">
                <div className="notification-title">{notification.title || 'Bez tytułu'}</div>
                <div className="notification-text">{notification.text || 'Bez treści'}</div>
                <div className="notification-date">{formatDate(notification.createdAt)}</div>
              </Menu.Item>
            ))}
            {notifications.filter(notification => notification.isRead).length > 5 && (
              <Menu.Item className="notification-item" onClick={showModal}>
                <Button type="link">Pokaż wszystkie przeczytane</Button>
              </Menu.Item>
            )}
          </Menu.ItemGroup>
        </>
      ) : (
        <Menu.Item>Brak nowych powiadomień</Menu.Item>
      )}
    </Menu>
  );

  const renderLogo = () => {
    if (companyLogo) {
      return <img src={companyLogo} alt="Company Logo" className="company-logo" />;
    } else if (companyName) {
      return (
        <div className="company-initial">
          {companyName.charAt(0).toUpperCase()}
        </div>
      );
    } else {
      return <img src={require('../assets/black.png')} alt="Default Logo" className="company-logo" />;
    }
  };

  return (
    <Header className="app-header">
      <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        {renderLogo()}
      </div>
      <div className="page-title">
        <span>{getPageTitle()}</span>
      </div>
      <div className="notification-icon">
        <Dropdown
          overlay={notificationMenu}
          trigger={['click']}
          visible={menuVisible}
          onVisibleChange={handleMenuVisibleChange}
        >
          <Badge
            count={unreadCount}
            style={{ boxShadow: 'none', color: '#fff' }} 
          >
            <BellOutlined style={{ fontSize: '24px', color: 'black', cursor: 'pointer' }} />
          </Badge>
        </Dropdown>
      </div>

      {/* Модальное окно для всех прочитанных уведомień */}
      <Modal
        title="Wszystkie przeczytane powiadomienia"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Zamknij
          </Button>,
        ]}
      >
        <List
          itemLayout="horizontal"
          dataSource={allReadNotifications}
          renderItem={notification => (
            <List.Item key={notification.id}>
              <List.Item.Meta
                title={notification.title || 'Bez tytułu'}
                description={
                  <>
                    <div>{notification.text || 'Bez treści'}</div>
                    <div className="notification-date">{formatDate(notification.createdAt)}</div>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </Header>
  );
};

export default AppHeader;
