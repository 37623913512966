import React from 'react';
import { useCompany } from '../contexts/CompanyContext';

const Header = () => {
  const companyData = useCompany();

  const truncateCompanyName = (name, maxLength = 30) => {
    if (name.length <= maxLength) {
      return name;
    }
    return name.slice(0, maxLength) + '...';
  };

  return (
    <header style={{
      padding: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row', // Горизонтальное расположение по умолчанию
    }}>
      <div style={{
        padding: '10px 20px',
        borderRadius: '12px',
        border: '1px solid #ddd',
        fontSize: '16px',
        backgroundColor: '#fff',
        textAlign: 'center',
        flex: '1',
        marginRight: '10px', // Отступ справа между блоками
        maxWidth: '400px', // Ограничиваем ширину блоков на десктопе
      }}>
        {companyData ? truncateCompanyName(companyData.companyName) : 'Loading...'}
      </div>
      <div style={{
        padding: '10px 20px',
        borderRadius: '12px',
        fontSize: '16px',
        backgroundColor: '#e0f64b',
        textAlign: 'center',
        flex: '1',
        maxWidth: '300px', // Ограничиваем ширину блоков на десктопе
      }}>
        {companyData ? (
          <a href={`tel:${companyData.phone}`} style={{ color: 'inherit', textDecoration: 'none' }}>
            Pomoc: {companyData.phone}
          </a>
        ) : ''}
      </div>
      <style>
        {`
          @media (max-width: 768px) {
            header {
              flex-direction: column !important; /* Меняем направление на колонку для мобильных устройств */
              align-items: center;
            }
            header div {
              width: 100% !important; /* Растягиваем блоки на всю ширину */
              max-width: none !important; /* Убираем ограничение ширины */
              margin-right: 0 !important; /* Убираем отступ справа между блоками */
              margin-bottom: 10px; /* Добавляем отступ снизу между блоками */
            }
            header div:last-child {
              margin-bottom: 0; /* Убираем отступ у последнего блока */
            }
          }
        `}
      </style>
    </header>
  );
};

export default Header;
