import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Input, Form, message, Spin, List } from 'antd';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import { collection, addDoc, serverTimestamp, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Lottie from 'lottie-react';
import successAnimation from '../components/lottie/success.json';
import { useAuth } from '../contexts/AuthContext';
import { useCompany } from '../contexts/CompanyContext';
import './ReferralCard.css';

const ReferralCard = () => {
  const { currentUser } = useAuth();
  const companyData = useCompany();
  const companyId = companyData?.companyId;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReferralsModalOpen, setIsReferralsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [form] = Form.useForm();
  const [userData, setUserData] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(true);
  const [referrals, setReferrals] = useState([]);
  const [loadingReferrals, setLoadingReferrals] = useState(false);

  // Функция для загрузки данных пользователя
  const fetchUserData = useCallback(async () => {
    if (currentUser && companyId) {
      try {
        const userDocRef = doc(db, `companies/${companyId}/users`, currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData({
            uid: currentUser.uid,
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            phoneNumber: data.phone || '',
          });
        } else {
          throw new Error('Nie można znaleźć danych użytkownika.');
        }
      } catch (error) {
        message.error(error.message);
        console.error(error);
      } finally {
        setLoadingUserData(false);
      }
    } else {
      message.error('Nie jesteś zalogowany lub brak danych firmy.');
    }
  }, [currentUser, companyId]);

  // Загрузка данных пользователя при монтировании компонента
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleModalOpen = useCallback(() => {
    if (!userData && !loadingUserData) {
      message.error('Nie można uzyskać danych użytkownika.');
      return;
    }
    setIsModalOpen(true);
  }, [userData, loadingUserData]);

  const handleReferralsModalOpen = useCallback(async () => {
    if (!userData && !loadingUserData) {
      message.error('Nie można uzyskać danych użytkownika.');
      return;
    }

    setLoadingReferrals(true);

    try {
      const referralsQuerySnapshot = await getDocs(collection(db, `companies/${companyId}/users/${userData.uid}/ReferalProgram`));
      const referralsData = [];

      const referralPromises = referralsQuerySnapshot.docs.map(async (docRef) => {
        const referralDoc = await getDoc(doc(db, `companies/${companyId}/ReferalProgram`, docRef.data().referalDocId));
        if (referralDoc.exists()) {
          referralsData.push(referralDoc.data());
        }
      });

      await Promise.all(referralPromises);

      setReferrals(referralsData);
      setIsReferralsModalOpen(true);
    } catch (error) {
      message.error('Wystąpił błąd przy ładowaniu poleceń.');
      console.error('Ошибка при загрузке рекомендаций:', error);
    } finally {
      setLoadingReferrals(false);
    }
  }, [userData, loadingUserData, companyId]);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setFormVisible(true);
    setShowSuccessAnimation(false);
  }, []);

  const handleReferralsModalClose = useCallback(() => {
    setIsReferralsModalOpen(false);
  }, []);

  const handleFormSubmit = useCallback(async (values) => {
    if (!userData || !companyId) {
      message.error('Nie można uzyskać danych użytkownika lub firmy.');
      return;
    }

    setIsSubmitting(true);
    setFormVisible(false);

    const { uid, firstName, lastName, phoneNumber } = userData;
    const recommendedBy = { uid, firstName, lastName, phoneNumber };

    try {
      const referalDocRef = await addDoc(collection(db, `companies/${companyId}/ReferalProgram`), {
        recommendedBy,
        phone: values.phone || '',
        email: values.email || '',
        timestamp: serverTimestamp(),
        status: 'Oczekuje',
      });

      await addDoc(collection(db, `companies/${companyId}/users/${uid}/ReferalProgram`), {
        referalDocId: referalDocRef.id,
        timestamp: serverTimestamp(),
      });

      message.success('Polecenie zostało zapisane w bazie danych i oczekuje na administratora.');
      setShowSuccessAnimation(true);

      setTimeout(() => {
        setIsModalOpen(false);
        form.resetFields();
        setFormVisible(true);
        setShowSuccessAnimation(false);
      }, 3000);
    } catch (error) {
      message.error('Wystąpił błąd przy zapisywaniu polecenia.');
      console.error('Ошибка при записи danych w Firestore:', error);
      setFormVisible(true);
    } finally {
      setIsSubmitting(false);
    }
  }, [userData, companyId, form]);

  return (
    <>
      <Card variant="solid" sx={{ backgroundColor: 'black', color: 'white', borderRadius: '12px', marginTop: '20px' }} invertedColors>
        <CardContent orientation="horizontal">
          <CardContent>
            <Typography level="h2" sx={{ color: 'white' }}>Program poleceń</Typography>
            <Typography level="body-md" sx={{ color: 'white' }}>Zaproś znajomego i otrzymaj nagrody za polecenie.</Typography>
          </CardContent>
        </CardContent>
        <CardActions>
          <Button
            variant="solid"
            size="sm"
            sx={{ backgroundColor: '#e0f64b', color: 'black', borderRadius: '10px', width: '100%' }}
            onClick={handleModalOpen}
          >
            Poleć znajomego
          </Button>
          <Button variant="soft" size="sm" sx={{ color: 'white', borderRadius: '10px', width: '100%' }} onClick={handleReferralsModalOpen}>
            Twoje polecenia
          </Button>
        </CardActions>
      </Card>

      <Modal
        title="Polecenie znajomego"
        visible={isModalOpen}
        onOk={() => form.submit()}
        onCancel={handleModalClose}
        okText="Zatwierdź"
        cancelText="Anuluj"
        confirmLoading={isSubmitting}
      >
        {loadingUserData ? (
          <Spin tip="Ładowanie danych użytkownika..." />
        ) : (
          <div className={`form-container ${formVisible ? 'visible' : 'hidden'}`}>
            <Form layout="vertical" form={form} onFinish={handleFormSubmit}>
              <Form.Item
                label="Numer telefonu"
                name="phone"
                rules={[
                  { required: true, message: 'Proszę wprowadzić numer telefonu lub adres e-mail!' },
                  { pattern: /^[0-9]+$/, message: 'Proszę wprowadzić poprawny numer telefonu!' },
                ]}
              >
                <Input placeholder="Wprowadź numer telefonu" />
              </Form.Item>
              <Form.Item
                label="Adres e-mail"
                name="email"
                rules={[
                  { type: 'email', message: 'Proszę wprowadzić poprawny adres e-mail!' },
                ]}
              >
                <Input placeholder="Wprowadź adres e-mail" />
              </Form.Item>
            </Form>
          </div>
        )}

        {showSuccessAnimation && (
          <div className="animation-container">
            <Lottie animationData={successAnimation} loop={false} />
          </div>
        )}
      </Modal>

      <Modal
        title="Twoje polecenia"
        visible={isReferralsModalOpen}
        onCancel={handleReferralsModalClose}
        footer={null}
      >
        {loadingReferrals ? (
          <Spin tip="Ładowanie twoich poleceń..." />
        ) : (
          <List
            itemLayout="vertical"
            dataSource={referrals}
            renderItem={item => (
              <List.Item key={item.uid}>
                <List.Item.Meta
                  title={`Status: ${item.status}`}
                />
                <p>Numer telefonu: {item.phone}</p>
                <p>Adres e-mail: {item.email || 'Brak'}</p>
              </List.Item>
            )}
          />
        )}
      </Modal>
    </>
  );
};

export default ReferralCard;
